body {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto,
    Segoe UI, Helvetica Neue, sans-serif;
}

.left {
  float: left;
}
.right {
  float: right;
}
.clear:after {
  clear: both;
}
.break {
  flex-basis: 100%;
  height: 0;
}

.order-details__header {
  margin-top: 1.4rem;
  margin-bottom: 0;
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 59.8rem;
  box-sizing: border-box;
  padding: 0 1rem;

  .title-bar__navigation {
    min-height: 1.6rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-bar__pagination {
      display: none;
    }

    svg.breadcrumb {
      background: transparent;
      fill: #637381;
      margin: 0;
      height: 1.2rem;
      display: inline-block;
      padding: 0;
    }

    span.breadcrumb__item {
      font-weight: 300;
      vertical-align: bottom;
    }

    .pagination-buttons__container {
      display: flex;

      .pagination__back-button {
        padding-right: 9px;

        svg.back-button {
          fill: #637381;
          width: 1rem;
          transform: rotate(0.75turn);
        }
      }

      .pagination__next-button {
        padding-left: 9px;

        svg.next-button {
          fill: #637381;
          width: 15px;
          transform: rotate(0.25turn);
        }
      }
    }
  }

  .order-header__container {
    padding-left: 5px;

    .order-header__row {
      // display: flex;

      span {
        color: #212b36;
      }

      span.order-header__order_number {
        font-weight: 500;
        color: rgb(17, 17, 17);
        font-size: 1.75rem;
        line-height: 2.2rem;
        margin-right: 0.3rem;
        overflow: hidden;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
      }

      span.order-header__store {
        margin-right: 0.7rem;
        span {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }

      span.order-header__orderhub-status {
        .header-status {
          text-transform: capitalize;
          display: inline-block;
        }
      }
    }
  }

  .order-actions__container {
    margin-top: 10px;
    margin-bottom: -10px;
    margin-left: -10px;

    .order-actions__row {
      display: flex;
      margin-bottom: 40px;
      > div {
        margin-left: 15px;
      }
      div[disabled] {
        opacity: 0.45;

        &:hover {
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);
        }
      }
    }

    .hold-action-container,
    .cancel-action-container {
      border: 1px solid #ddd;
      width: fit-content;
      padding: 5px 5px 5px 10px;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
      transition: all 0.5s;

      &:hover {
        box-shadow: 2px 2px 5px 2px rgba(63, 63, 68, 0.05),
          2px 2px 5px 2px rgba(63, 63, 68, 0.15);
        cursor: pointer;
      }
    }

    span {
      margin: 0 5px 0 0px;
      color: #637381;
    }

    span.order-actions__print {
      margin-left: 0;
    }

    span.print-icon img {
      width: 20px;
      fill: #637381;
      vertical-align: top;
      //margin-right: -10px;
    }

    span.refund-icon img {
      width: 20px;
      fill: #637381;
      vertical-align: top;
      //margin-right: -25px;
    }

    span.hold-icon img {
      width: 18px;
      vertical-align: top;
      fill: #637381;
      //margin-right: -25px;
    }

    span.open-dropdown-icon img {
      width: 20px;
      fill: #637381;
      vertical-align: top;
      //margin-left: -25px;
      display: none;
    }

    span.order-actions__more-actions {
      display: none;
    }
  }
}

.order-details__body {
  margin-top: 1rem;
  margin-bottom: 1rem;
  // padding-right: 2.2rem;
  // padding-left: 2.2rem;
  margin-right: auto;
  margin-left: auto;
  display: block;
  // max-width: 59.8rem;
  box-sizing: border-box;

  .order-details__section {
    &.order-details__section-primary {
      .order-information__container {
        box-sizing: border-box;
        max-width: 100%;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15);
        padding: 20px 15px;
        margin-bottom: 20px;
      }
      .order-tracking-info__container {
        box-sizing: border-box;

        padding-left: 35px;
        margin-bottom: 10px;
        // background: red;
        .tracking-header {
          font-size: 1rem;
          color: #212b36;
          margin-bottom: 5px;
          display: none;
        }

        .order-tracking-info {
          .carrier {
            text-transform: capitalize;
          }
        }
      }

      .order-errors__container {
        background: #fff;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15);
        padding: 20px 15px;
        margin-bottom: 30px;

        .error-title span.bold {
          text-transform: capitalize;
        }

        .individual-errors__container {
          text-align: right;
        }
        .order-error-title__container {
          margin-bottom: 20px;

          img.error-icon {
            width: 30px;
          }

          .order-error-title {
            font-size: 1rem;
            font-weight: 500;
            color: #212b36;
            margin-left: 7px;
            vertical-align: middle;
          }
        }

        .order-error-detail__container {
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);
          padding: 5px 20px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;

          div {
            margin: 3px 0;
          }

          .error-product-title {
            color: #007ace;
          }

          .single-error {
            // margin: 10px 0;
            color: #ee5252;
          }
        }
      }

      .order-payment__container {
        background: #fff;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15);
        padding: 20px 15px;
        margin-bottom: 30px;

        img.paid-icon {
          width: 32px;
        }

        .order-payment-title__container {
          margin-bottom: 20px;

          .order-payment-title {
            font-size: 1rem;
            font-weight: 500;
            color: #212b36;
            margin-left: 7px;
            // margin-bottom: 30px;
          }
        }

        .order-payment__totals {

          .total {
            font-weight: 600;
          }

          .paid-by-customer.row {
            margin-top: 15px;
            margin-bottom: 0px;
          }

          .tax-line {
            margin: 0;
          }
        }
      }
    }

    &.order-details__section-secondary {
      .order-notes__container {
        padding: 20px 15px;
        background: #fff;
        box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
          0 1px 3px 0 rgba(63, 63, 68, 0.15);
        margin-bottom: 15px;

        .order-notes-title {
          color: #212b36;
          font-size: 1rem;
          margin-bottom: 20px;
        }
      }

      .order-customer__container {
        background: #fff;
        // box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);

        .sub-header {
          text-transform: uppercase;
          font-size: 0.78rem;
          color: #212b36;
          margin-bottom: 20px;
        }

        .order-customer-title {
          font-size: 1rem;
          color: #212b36;
          margin-bottom: 20px;
        }

        .customer-basic-info__container {
          padding: 20px 15px;
          background: #fff;
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);

          div {
            color: #212b36;
          }

          .customer-name {
            text-transform: capitalize;
          }
          .customer-order-total {
            color: #212b36;
          }
        }

        .customer-contact-info__container {
          padding: 20px 15px;
          background: #fff;
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);

          div:not(.sub-header) {
            color: #212b36;
            text-transform: lowercase;
          }
        }

        .customer-shipping-info__container,
        .customer-billing-info__container {
          padding: 20px 15px;
          background: #fff;
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);

          a.view-map {
            div {
              margin-top: 15px;
              color: #007ace;
            }

            &:hover * {
              cursor: pointer !important;
            }
          }
        }
      }
    }
  }

  .order-items-header__container {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 20px;

    span.order-items-header__orderhub-status,
    span.order-items-header__orderhub-status div {
      font-size: 1.1rem;
      text-transform: capitalize;
      color: #212b36;
      font-weight: 500;
      line-height: 16px;
    }

    span.order-items-header__address {
      padding-top: 0px;
      line-height: 34px;
      color: #212b36;
    }
  }

  .single-item__container {
    box-sizing: border-box;
    margin-bottom: 1.3rem;
    padding-bottom: 1.3rem;
    border-bottom: 1px #e6e6e6 solid;

    .personalization-info__container {
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
      padding: 15px 10px;

      .single-item-preview-image__container {
        // background: red;
        margin-bottom: 15px;
      }

      .control-panel__label {
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 0.78rem;
        color: #212b36;
      }

      ul.customized-text {
        list-style: none;
        padding: 0;

        li {
          font-weight: 300;

          span {
            color: #212b36;
            font-weight: 400;
          }
        }
      }

      .control-panel__stat {
        margin-top: 2px;

        a.preview-image-link {
          z-index: 900;
        }
        img.preview-image {
          width: auto;
          height: 200px;
          box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15);
        }
      }
    }

    .single-item-vendor-info__container {
      margin-top: 0.2rem;
      margin-bottom: 1rem;
      margin-left: 2.2rem;
      justify-content: space-between;
      display: flex;

      span {
        text-transform: capitalize;
        margin-right: 0px;
      }

      span.single-item-vendor-status div {
        font-size: 1rem;
        font-weight: 500;
        padding: 0.3rem 0.7rem;
        color: #212b36;
        margin-top: -5px;
      }

      span.single-item-vendor-number div {
        display: inline-block;
        // min-width: 50px;
        span {
          margin: 0;
          // width: 50px;
        }
      }

      span.single-item-vendor-name div {
        display: inline-block;
        text-transform: capitalize;
        color: #212b36;

        span {
          margin: 0;
          color: #212b36;
        }
      }

      span.single-item-vendor-number * {
        color: #212b36;

        span {
          margin: 0;
        }
      }
    }

    .single-item-details__container {
      margin-bottom: 16px;
      display: flex;
      .single-item-money__container {
        flex: 0 0 200px;
      }

      .single-item-image__container {
        min-width: 30px;
        position: relative;
        flex: 0 0 3%;
        margin-right: 16px;

        .single-item-qty {
          position: absolute;
          left: -3px;
          background: #919eab;
          padding: 0px 10px;
          border-radius: 25px;
          border: 2px solid #fff;
          color: #fff;
        }
      }

      .single-item-title-variants__container {
        flex: 1 1 auto;
        margin-right: 16px;
        div:not(.single-item-preview-image__container) {
          margin-bottom: 1px;
        }

        .single-item-title {
          color: #007ace;
        }
      }
    }
  }
}

.single-cancel-item__container {
  width: 100%;
  display: block;
  float: left;
  margin: 0.6rem 0.3rem;
  padding: 0.5rem;
  background: #fcfcfc;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .single-item__name {
    color: #212b36;
  }
}

.item-bubble {
  width: 9rem;
  border-radius: 5rem;
  margin: 0 auto;
  padding: 0.1rem;
  color: #212b36;
}

.btn:before {
  position: absolute;
  height: 0;
  width: 0;
  border-radius: 50%;
  background: linear-gradient(180deg, #c4cdd5, #bac2c9);
  box-shadow: inset 0 1px 0 0 #bac2c9, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  content: '';
  top: 0;
  left: 0;
}

.selected-cancel-item {
  background: #007ace;
}

.order-details__section {
  &.order-details__section-primary {
    padding: 0 !important;
  }

}

// Timeline component for order details
.order-timeline__container {
  border: none;
}

.timeline-header {
  font-size: 1rem;
  padding: 0 1rem 1.2rem;
  border-bottom: 1px solid #d3dbe2;
  flex-shrink: 0;

  .timeline-header-title {
    font-size: 1.3rem;
  }

  .timeline-header-tagline {
    margin-left: 1rem;
    font-size: 0.8rem;
    font-weight: 900;
  }
}

.timeline-action__container {
  margin: 0 0 4rem 0;
  padding: 1rem;
  font-size: 0.8rem;
}

.timeline-action-days {
  margin: 0 0 1rem 0;
}

.timeline-action-row {
  display: flex;
  padding: 0.4rem 0;

  .timeline-action-message {
    justify-content: flex-start;
    margin-right: auto;
  }

  .timeline-action-time {
    justify-content: flex-end;
    margin-left: 1rem;
    width: 5rem;
  }
}

// general classes to use across site
.bold {
  font-weight: 500;
}

.semi-bold {
  font-weight: 400;
}

section.sidebar__scroll.scroll:before {
  content: 'Snow Axis v2.6.0.12';
  height: 3rem;
  width: 100%;
  position: absolute;
  bottom: 1rem;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 200;
  color: #555555;
  background: transparent;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
}

.modal-content {

  color:#637381;
  padding: 15px;
  background: #f9fafb;

  .close-icon__container {
    margin-bottom: 0px;
  }

  .warning-icon__container {
    text-align: center;
    .warning-icon {
      width: 50px;
    }
  }
}

.btn-info:before {
  background-color: grey;
}

.modal-content-body {
  padding: 60px 40px 40px;
  margin: 15px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 2px;

  h3 {
    margin-bottom: 20px;
    color: #212b36;
    font-weight: 500;
    font-size: 1.3rem;
  }
}
.modal-content-cancel-body {
  padding: 1.2rem 1rem 1rem;
  margin: 15px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 2px;

  h3 {
    margin-bottom: 20px;
    color: #212b36;
    font-weight: 500;
    font-size: 1.3rem;
  }
}
.modal-buttons__container {
  justify-content: center;
  padding-top: 1rem;
}

button#modal-cancel {
  border: 0.1rem solid #c4cdd5;
  color: #fff;
  font-weight: 300;
  background: #c4cdd5;
  width: 130px;
  background: linear-gradient(180deg, #c4cdd5, #bac2c9);
  box-shadow: inset 0 1px 0 0 #bac2c9, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  transition: background 0.5s;
  border-radius: 3px;
  padding: 7px 10px;

  &:before {
    width: 0;
  }

  &:hover {
    color: #fff;
    background: linear-gradient(180deg, #b5bcc2, #aab0b6);
    border: 0.1rem solid #c4cdd5;
  }
}

button#modal-hold {
  color: #fff;
  font-weight: 300;
  width: 130px;
  transition: all 0.5s;
  background: linear-gradient(180deg, #007ace, #036cb4);
  box-shadow: inset 0 1px 0 0 #036cb4, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  transition: all 0.5s;
  border-radius: 3px;
  padding: 7px 10px;

  &:before {
    width: 0;
  }

  &:hover {
    background: linear-gradient(180deg, #0370ba, #0462a4);
  }
}

.confirm-modal-title {
  text-align: center;
  margin: 20px;
}

.confirm-modal-message {
  text-align: center;
  margin-bottom: 20px;
  color: #212b36;

  .error-message {
    color: #ee5354;
  }
}

.confirm-modal-footer {
  text-align: center;
}

button#modal-confirm-ok {
  color: #fff;
  font-weight: 300;
  width: 120px;
  transition: all 0.5s;
  background: linear-gradient(180deg, #007ace, #036cb4);
  box-shadow: inset 0 1px 0 0 #036cb4, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  transition: all 0.5s;
  padding: 7px 10px;
  border-radius: 3px;

  &:before {
    width: 0;
  }

  &:hover {
    background: linear-gradient(180deg, #0370ba, #0462a4);
  }
}

.single-item-money__container .row {
  margin-left: 0;
}

.single-item-money__container .row {
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;
}

.order-payment__totals .row .col-lg-1 {
  justify-content: flex-end;
  display: flex;
  padding-right: 0 !important;
}

span.am-pm {
  text-transform: lowercase;
}

.customized-text li.personalized-text {
  color: #212b36;
  font-weight: 500 !important;
}
