.loading-icon {
  height: 50px;
  width: 50px;
  animation: rotation 1.2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-icon-container.dashboard {
  height: 400px;
  text-align: center;

  img {
    margin-top: 150px;
  }
}

.loading-icon-container.load {
  display: none;
  height: 400px;
  padding-left: 49vw;
  text-align: center;
  img {
    margin-top: 500px;
  }
}
