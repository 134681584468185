// product detail view styles
.market-attribute-key {
  text-transform: capitalize;
  margin-right: 5px;
}

.order-information__container.product-details .row {
  padding: 5px 0;
}

.product-details .col-lg-6 {
  padding: 0 40px;
}

.order-information__container.product-details .values .row {
  font-weight: 500;
}

ul.market-attributes {
  padding: 0;
}

.product-detail-header {
  font-size: 1.3rem;
  font-weight: 500;
  color: #212b36;
  margin-left: 7px;
  line-height: 2.1;
}

img.product-icon {
  height: 38px;
  width: 38px;
  margin-left: 20px;
}

img.store-icon {
  height: 34px;
  width: 38px;
  margin-left: 20px;
}

.product-sidebar-container {
  padding: 20px 15px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin-bottom: 15px;
}

.product-sidebar.header {
  color: #212b36;
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 500;
}

img.no-product-image {
  opacity: 0.6;
}

.no-product-image-container {
  position: relative;
}

.no-product-image-text {
  position: absolute;
  top: 35%;
  font-size: 2.1rem;
  color: rgba(220, 53, 69, 0.8);
  margin-left: 2vw;
}

@media screen and (max-width: 1150px) {
  .no-product-image-text {
    font-size: 1.5rem;
  }
}

.order-information__container.product-details .row {
  word-break: break-word;
}

.product-details .keys {
  text-transform: capitalize;
}

.product-details .values {
  font-weight: 500;
}

.product-details a:hover {
  cursor: pointer !important;
}

.product-actions__container {
  margin-top: 10px;
  margin-bottom: 30px;

  .resync-action-container {
    border: 1px solid #ddd;
    width: fit-content;
    padding: 5px 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    margin-left: 5px;
    transition: all 0.5s;

    &:hover {
      box-shadow: 2px 2px 5px 2px rgba(63, 63, 68, 0.05),
        2px 2px 5px 2px rgba(63, 63, 68, 0.15);
      cursor: pointer !important;
    }
    span {
      margin: 0 10px;
      color: #637381;
    }

    span.resync-icon img {
      width: 20px;
      fill: #637381;
      vertical-align: top;
      margin-right: -10px;
    }
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.resync-icon.spinner {
  width: 50px;
  animation: rotating 1.5s linear infinite;
  margin-top: 20px;
}
.modal-content-body p {
  padding: 0;
}
